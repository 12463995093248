import * as React from 'react';
import styled from 'styled-components';
import { Spacer, Text } from '../../parts';

interface SignUpPromoProps {}

export const SignUpPromo: React.FC<SignUpPromoProps> = (props) => {
	const {} = props;

	return (
		<SignUpPromoBody>
			<Cont>
				<Text weight="heavy" size={40} color="white" style={{ textAlign: 'center' }}>
					See how much you can save.
				</Text>
				<Spacer size={15} />
				<GetBtn onClick={() => window.open('https://liverewardsgo.com')}>Get Started</GetBtn>
				{/* <Spacer size={4} />
				<Text size={14} style={{ fontStyle: 'italic' }} color="white">
					$3.99/Month after the 14 day trial
				</Text> */}
			</Cont>
		</SignUpPromoBody>
	);
};

const SignUpPromoBody = styled.div`
	height: 200px;
	width: 100%;
	background-image: linear-gradient(280deg, #91c1f4 0%, #027dfd 70%);
`;

const Cont = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	align-items: center;

	@media only screen and (min-width: 1200px) {
		margin: 0 auto;
		max-width: 1200px;
	}
`;

const GetBtn = styled.p`
	padding: 6px 34px;
	background: white;
	font-weight: 700;
	border-radius: 50px;
	user-select: none;
	cursor: pointer;

	transition: opacity 0.1s ease-in-out;
	&:hover {
		opacity: 0.5;
	}
	&:active {
		opacity: 0.4;
	}
`;
