export const list = [
	{ title: 'Wallmart', logo: 'https://home.ibotta.com/wp-content/uploads/2020/09/v2-retailer-walmart.png' },
	{ title: 'Expedia', logo: 'https://home.ibotta.com/wp-content/uploads/2020/09/v2-retailer-expedia.png' },
	{ title: 'GameStop', logo: 'https://home.ibotta.com/wp-content/uploads/2020/10/retailer-gamestop.png' },
	{ title: 'HomeDepot', logo: 'https://home.ibotta.com/wp-content/uploads/2020/09/v2-retailer-homedepot.png' },
	{ title: 'Staples', logo: 'https://home.ibotta.com/wp-content/uploads/2020/10/Staples_bonuses_1.png' },
	{ title: `Petco`, logo: 'https://home.ibotta.com/wp-content/uploads/2020/09/retailer-petco.png' },
	{
		title: `Disneyland`,
		logo: 'https://cdn.yourstatus.app/disneyland.jpeg',
	},
];

export const reviews = [
	{
		user_img: 'https://home.ibotta.com/wp-content/uploads/2020/09/saver-6.png',
		name: 'Alex C.',
		message: `“So I've only used this app for an entire year & I am impressed at it. You take like 10 minutes to look at what's available, you can plan your meals & save a lot of money. Thanks Live Rewards!”`,
	},
	{
		user_img: 'https://home.ibotta.com/wp-content/uploads/2020/09/saver-2.png',
		name: 'Jessica F.',
		message: `As a stay-at-home mom, I am so thankful for Live Rewards because it helps us stretch our grocery budget, put money away for vacations and help others all at the same time!`,
	},
	{
		user_img: 'https://home.ibotta.com/wp-content/uploads/2020/09/saver-5.png',
		name: 'Devon L.',
		message: `I've been using the Live Rewards App like a mad woman at the Home Depot and Lowe's. House renovations are killer. A vacation would be lovely! Thanks Live Rewards!`,
	},
];
