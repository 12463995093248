import * as React from 'react';
import styled from 'styled-components';
import { Text, Spacer, Row } from '../../parts';

import a1 from '../../assets/illustrations/phone-1.svg';
import a2 from '../../assets/illustrations/notepad-2.svg';
import a3 from '../../assets/illustrations/wallet-3.svg';

interface HowItWorksProps {}

export const HowItWorks: React.FC<HowItWorksProps> = (props) => {
	const {} = props;

	const headingSize = 21;

	return (
		<HowItWorksBody>
			<Text size={30} weight="black">
				How it works
			</Text>
			<Spacer size={40} />
			<HowItWorksItemContainer>
				<Box>
					<IconImg src={a1} alt="phone" />

					<Text weight="heavy" size={headingSize} center style={{ lineHeight: 1 }}>
						Try it out for free
					</Text>
					<Desc>Get started with our free trial and start saving immediately</Desc>
				</Box>
				<Box>
					<IconImg src={a2} alt="notepad" />
					<Text weight="heavy" size={headingSize} center style={{ lineHeight: 1 }}>
						Discover thousands of amazing offers
					</Text>
					<Desc>We are partnered with thousands of retailers across the country</Desc>
				</Box>
				<Box>
					<IconImg src={a3} alt="wallet" />
					<Text weight="heavy" size={headingSize} center style={{ lineHeight: 1 }}>
						Enjoy thousands of dollars in savings per year
					</Text>
					<Desc>Members typically save between 30%-50% on all purchases</Desc>
				</Box>
			</HowItWorksItemContainer>
		</HowItWorksBody>
	);
};

const HowItWorksItemContainer = styled(Row)`
	justify-content: space-around;
	width: 100%;
	align-items: baseline;
	@media only screen and (max-width: 600px) {
		align-items: center;
		flex-direction: column;
		> div {
			margin-bottom: 60px;
		}

		> div:last-child {
			margin-bottom: 0px;
		}
	}
`;

const HowItWorksBody = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 20px;
	padding: 20px 30px 40px 30px;
	box-shadow: 0px 9px 26px 0px rgb(0 0 0 / 8%);
	border: solid 1px #efefef;
	width: 85%;

	@media only screen and (max-width: 800px) {
		max-width: 900px;
	}
`;

const IconImg = styled.img`
	height: 120px;
	margin-bottom: 25px;
	width: auto;
`;

const Box = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 250px;
`;

const Desc = styled(Text).attrs({ weight: 'light', center: true, color: 'gray' })`margin-top: 10px;`;
