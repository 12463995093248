import * as React from 'react';
import styled from 'styled-components';
import banner_img from '../assets/illustrations/people_banner-01.svg';

import { list, reviews } from '../data';
import { Row, Text, Spacer, Center } from '../parts';
import { Footer } from './parts/Footer';
import { Numbers } from './parts/Numbers';
import { HowItWorks } from './parts/HowItWorks';
import { SignUpPromo } from './parts/SignUpPromo';

interface HomeProps {
}

export const Home: React.FC<HomeProps> = (props) => {
	const openAppInStore = () => {
		if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
			window.location.href = 'https://play.google.com/store/apps/details?id=com.access.liverewards&hl=en&gl=US';
		}
		if (navigator.userAgent.toLowerCase().indexOf('iphone') > -1) {
			window.location.href = 'https://apps.apple.com/us/app/live-rewards/id1470069033';
		}
	};
	return (
		<HomeBody>
			<BannerContainer>
				<Container style={{ alignItems: 'flex-start' }}>
					<Spacer size={30} />
					<Text weight='heavy' size={40} color='#0d2b2f'>
						Live Rewards
					</Text>
					<Spacer size={10} />
					<Text weight='semi-bold' size={28} color='#165a63' style={{ maxWidth: 700 }}>
						The exclusive discount membership program that saves you money wherever you shop
					</Text>
					<Spacer size={25} />
					<GetNowBtn onClick={() => window.location.href = 'https://liverewardsgo.com'}>Get Started</GetNowBtn>
					<Spacer size={20} />
					<Text weight='bold' size={20} color='#497f86'>
						Exclusive Discounts | Local Deal Alerts | Nationwide Availability
					</Text>
				</Container>

				<Banner src={banner_img} alt='banner' />
			</BannerContainer>

			<Container>
				<IntroVideoCont>
					<div
						dangerouslySetInnerHTML={{
							__html: `
							<video class='video-mobile-fix' width='320' height='240' autoplay loop muted playsinline >
							<source src='https://cdn.yourstatus.app/Live+Rewards+Ad+(1).mp4' type='video/mp4' />
						</video>
      `
						}}
					></div>

				</IntroVideoCont>

				<Spacer size={70} />

				<HowItWorks />

				<Spacer size={140} />

				<Center style={{ maxWidth: 700 }}>
					<Text size={20} center style={{ letterSpacing: 4 }}>
						Exclusive discounts for all your favorite brands
					</Text>
					<Spacer size={10} />
					<Text weight='black' size={45} center>
						Whenever, however you shop
					</Text>
					<Spacer size={10} />
					<Text center color='#3d3d3d'>
						Whether you’re buying groceries, chowing down on takeout, or giving your wardrobe a serious upgrade, start
						using Live Rewards and save on thousands of in-store and
						online offers.
					</Text>
				</Center>

				<Spacer size={60} />

				<CompaniesPartnered>
					{list.map((v, i) => (
						<CompaniesItem key={i}>
							<LogoCircle src={v.logo} alt={v.title} height={90} width={90} />
							<Text weight='semi-bold'>{v.title}</Text>
						</CompaniesItem>
					))}
				</CompaniesPartnered>

				<Spacer size={150} />
			</Container>

			<SignUpPromo />

			<Container>
				<Spacer size={150} />

				<Text weight='black' size={50} center>
					What Live Rewards Members are saying
				</Text>

				<Spacer size={130} />

				<ReviewsContainer>
					{reviews.map((v, i) => (
						<ReviewCard key={i}>
							<ReviewProfileImg src={v.user_img} alt={v.user_img + '_image'} height='150' width='150' />
							<Spacer size={10} />
							<Text weight='black' size={24}>
								{v.name}
							</Text>
							<Spacer size={20} />
							<Text color='#3b3b3b' weight='light' center>
								{v.message}
							</Text>
						</ReviewCard>
					))}
				</ReviewsContainer>

				<Numbers />

				<Spacer size={200} />
			</Container>

			<Footer />
		</HomeBody>
	);
};

const HomeBody = styled.div``;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  /* align-items: ; */
  padding: 0px 0px;

  @media only screen and (max-width: 1200px) {
    padding: 0px 20px;
    max-width: 1200px;
    align-items: center;
  }
`;

const Banner = styled.img`
   //max-height: 600px; 
  width: 100%;
  background-position: center left;
  background-repeat: no-repeat;
  background-size: cover;
`;

const LogoCircle = styled.img`
  height: 90px;
  width: 90px;
  border-radius: 180px;
  margin: 0px 20px;
  margin-bottom: 10px;
  box-shadow: 0 0 10px 1px #00000026;
  opacity: 0.7;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 1;
  }
`;

const CompaniesItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  opacity: 0.8;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 1;
  }
`;

const CompaniesPartnered = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const ReviewProfileImg = styled.img`
  height: 150px;
  width: 150px;
  position: absolute;
  top: -50px;
  border-radius: 200px;
`;

const ReviewCard = styled.div`
  box-shadow: 0px 5px 12px rgb(0 0 0 / 10%);
  padding: 20px 20px 20px 20px;
  border-radius: 20px;
  min-width: 270px;
  width: 270px;
  min-height: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 25px;
  position: relative;
  padding-top: 100px;
  margin-bottom: 150px;
`;

const ReviewsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  @media only screen and (min-width: 1200px) {
    justify-content: center;
    max-width: 1200px;
    align-items: center;
  }
`;

const GetNowBtn = styled.div`
  font-weight: 600;
  background-color: white;
  box-shadow: 0 2px 5px 1px #00000024;
  padding: 8px 15px;
  border-radius: 50px;
  cursor: pointer;
  user-select: none;
  width: fit-content;

  transition: transform 0.2s ease;

  &:hover {
    transform: translateY(-3px);
  }

  &:active {
    transform: scale(1.05) translateY(-4px);
  }
`;

const BannerContainer = styled.div`
  background-color: #c8eef3;
`;


const IntroVideoCont = styled(Center)`
  margin-top: -50px;

  @media only screen and (max-width: 500px) {
    padding-bottom: 0px;
    margin-top: -30px;
  }
  @media only screen and (max-width: 600px) {
    margin-top: -15px;
  }
`;
