import styled from 'styled-components';
import { Text, Fill, Row, Spacer, BoxWrapper, IconButton, Icon } from '../parts';
import logo from '../assets/liverewards_logo@0.2x.png';

const ICON_SIZE = 20;
const ICON_COLOR = '#454444';

export const Header: React.FC = () => {
	const go = (v: string) => {
		window.location.assign(v);
	};

	return (
		<HeaderB>
			<Cont row>
				<img src={logo} alt="logo" height="70" width="90" onClick={() => window.open('/', '_self')} style={{ cursor: 'pointer', objectFit: 'contain' }} />
				<Fill />
				<TextBtn>Home</TextBtn>
				<Spacer size={15} />
				<TextBtn onClick={() => go('https://blog.liverewardsgo.com')}>Blog</TextBtn>
				<Spacer size={15} />
				<Icons>
					<Iconbtn name="instagram" size={ICON_SIZE} color={ICON_COLOR} onClick={() => go('https://www.instagram.com/liverewardstoday/')} />
					<Iconbtn name="tiktok" size={ICON_SIZE} color={ICON_COLOR} onClick={() => go('https://www.tiktok.com/@liverewards?is_copy_url=1&is_from_webapp=v1')} />
					<Iconbtn name="twitter" size={ICON_SIZE} color={ICON_COLOR} onClick={() => go('https://twitter.com/LiveRewardsgo')} />
					<Iconbtn name="linkedin" size={ICON_SIZE} color={ICON_COLOR} onClick={() => go('https://www.linkedin.com/company/live-rewards/')} />
					<Iconbtn name="facebook" size={ICON_SIZE} color={ICON_COLOR} onClick={() => go('https://www.facebook.com/Live-Rewards-107599831487842')} />
				</Icons>
				<Spacer size={15} />
				<DownloadBtnContainer>
					<DownloadButton onClick={() => (window.location.href = 'https://liverewardsgo.com')}>Get Started</DownloadButton>
				</DownloadBtnContainer>
			</Cont>
		</HeaderB>
	);
};

const HeaderB = styled.div``;

const DownloadBtnContainer = styled.div`
	@media only screen and (max-width: 500px) {
		/* margin-top: 20px; */
	}
	@media only screen and (max-width: 800px) {
		/* margin-top: 20px; */
		/* margin-bottom: 10px; */
	}
`;

const DownloadButton = styled.p`
	font-weight: 600;
	background-color: #027dfd;
	color: white;
	box-shadow: 0 2px 5px 1px #00000024;
	padding: 8px 15px;
	border-radius: 50px;
	cursor: pointer;
	user-select: none;
	width: fit-content;

	transition: transform 0.2s ease;
	&:hover {
		transform: translateY(-3px);
	}
	&:active {
		transform: scale(1.05) translateY(-4px);
	}
`;

const Cont = styled(BoxWrapper)`
	max-width: 1200px;
	@media only screen and (max-width: 1200px) {
		flex-wrap: wrap;
	}

	@media only screen and (max-width: 840px) {
		flex-wrap: wrap;
		padding-bottom: 10px;
	}

	@media only screen and (max-width: 600px) {
		padding-bottom: 10px;
	}
`;

const Icons = styled(Row)`
	@media only screen and (max-width: 700px) {
		div {
			height: 18px;
			width: 18px;
			margin: 0px 3px;
		}
	}
`;

const Iconbtn = styled(IconButton)`
	margin: 0px 5px;
	border-radius: 50px;
	background-color: #eeeeee;
	padding: 8px;

	transition: opacity 0.1s ease-in-out;
	&:hover {
		opacity: 0.3;
	}
`;

const TextBtn = styled(Text)`
	cursor: pointer;
	font-weight: 600;

	transition: opacity 0.1s ease-in-out;
	&:hover {
		opacity: 0.3;
	}
`;

const Inpt = styled.input`
	outline: none;
	border: none;
	background-color: #f1f1f1;
	padding: 14px 22px 14px 12px;
	border-radius: 10px;

	&::placeholder {
		color: #6e8098;
	}
`;
