import { SimpleCore } from 'simple-core-state';

interface ICoreType {
	isUsed: boolean;
}

const instance = new SimpleCore<ICoreType>({
	isUsed: false,
});

export const core = instance.core();
