import * as React from 'react';
import styled from 'styled-components';
import { Text, Spacer, Row } from '../../parts/';
import { useCountUp } from 'react-countup';
import ReactVisibilitySensor from 'react-visibility-sensor';
import { useSimple } from 'simple-core-state';
import { core } from '../../core';

interface NumbersProps {}
const DURATION = 1.5;
const START_ON_MOUNT = false;

export const Numbers: React.FC<NumbersProps> = (props) => {
	const isUsed = useSimple(core.isUsed);

	const countRef = React.useRef<any>();
	const countRef1 = React.useRef<any>();
	const countRef2 = React.useRef<any>();

	const a1 = useCountUp({
		ref: countRef1,
		end: 1500000,
		duration: DURATION,
		useEasing: true,
		startOnMount: START_ON_MOUNT,
		onReset: () => console.log('Resetted!'),
		formattingFn: (v) => (v > 1000000 ? v.toString().slice(0, 1) + '.' + v.toString().slice(1, 2) + 'M' : v.toString().slice(0, 3) + '.' + v.toString().slice(3, 6)),
	});
	const a2 = useCountUp({
		ref: countRef,
		end: 700000,
		duration: DURATION,
		useEasing: true,
		startOnMount: START_ON_MOUNT,
		onReset: () => console.log('Resetted!'),
		formattingFn: (v) => v.toString().slice(0, 3) + ',' + v.toString().slice(3, 6),
	});

	const a3 = useCountUp({
		ref: countRef2,
		end: 1000,
		useEasing: true,
		duration: DURATION,
		startOnMount: START_ON_MOUNT,
		onReset: () => console.log('Resetted!'),
		formattingFn: (v) => (v.toString().length > 3 ? v.toString().slice(0, 1) + ',' + v.toString().slice(1, 4) : v.toString()),
	});

	const startCounting = (v: any) => {
		if (v === false) return;

		if (isUsed === false) {
			core.isUsed.set(true);
			setTimeout(() => {
				console.log('starting to count up animation');
				a1.start();
				a2.start();
				a3.start();
			}, 100);
		}
	};

	return (
		<ReactVisibilitySensor onChange={(v) => startCounting(v)}>
			<NumbersContainer>
				<Text weight="black" size={32} center color="#383838">
					Live Rewards Members can't believe how much they are saving!
				</Text>
				<Spacer size={20} />
				<NumbersBox>
					<div>
						<Row>
							<NumbersBoxHeading>$</NumbersBoxHeading>
							<NumbersBoxHeading ref={countRef1} />
						</Row>
						<Text>Live Rewards Members have saved so far!</Text>
					</div>
					<div>
						<Row>
							<NumbersBoxHeading ref={countRef} />
							<NumbersBoxHeading>+</NumbersBoxHeading>
						</Row>
						<Text>Offers from different locations</Text>
					</div>
					<div>
						<Row>
							<NumbersBoxHeading ref={countRef2} />
							<NumbersBoxHeading>+</NumbersBoxHeading>
						</Row>
						<Text>Participating retailers</Text>
					</div>
				</NumbersBox>
			</NumbersContainer>
		</ReactVisibilitySensor>
	);
};

const NumbersContainer = styled.div`
	padding: 50px;
	border-radius: 15px;
	box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 15%);

	@media only screen and (max-width: 900px) {
		max-width: 800px;
		width: 85%;
		padding: 20px;

		> div {
			align-items: center;
			justify-content: center;
			flex-direction: column;

			/* Adding padding to the sections when mobile */
			> div {
				margin-bottom: 30px;
			}
			> div:last-child {
				margin-bottom: 0px;
			}
		}

		align-items: center;
	}
`;

const NumbersBox = styled.div`
	display: flex;
	justify-content: space-between;

	> div {
		align-items: center;
		display: flex;
		flex-direction: column;
		max-width: 200px;

		p {
			text-align: center;
		}

		p:last-child {
			opacity: 0.7;
		}
	}
`;

const NumbersBoxHeading = styled.span`
	color: #2b8ff9;
	font-weight: 800;
	font-size: 50px;
`;
