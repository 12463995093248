import { ReactNode, CSSProperties } from 'react';
import styled from 'styled-components';

interface TextConfig {
	size?: number;
	color?: string;
	weight?: 'light' | 'medium' | 'semi-bold' | 'bold' | 'heavy' | 'black';
	style?: CSSProperties;
	center?: boolean;
	onClick?: () => void;
	children?: ReactNode;
}

export const Text: React.FC<TextConfig> = (p) => {
	const { children, style } = p;
	return (
		<TextBody {...p} style={style}>
			{children}
		</TextBody>
	);
};

const TextBody = styled.p<TextConfig>`
	${({ size, color, weight, center }) => `
  font-size: ${size || 16}px;
  color: ${color || 'black'};
	width: fit-content;
  font-weight: ${weight === 'black' ? 900 : weight === 'heavy' ? 800 : weight === 'bold' ? 700 : weight === 'semi-bold' ? 600 : weight === 'light' ? 400 : 500};
	${center ? 'text-align:center;' : ''}
  `}
`;
