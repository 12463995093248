import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import { Header } from './components/Headers';
import { Home } from './screens/Home';

export const App = () => {
	return (
		<AppBody>
			<Header />
			<Routes>
				<Route path="/" element={<Home />} />
			</Routes>
		</AppBody>
	);
};

const AppBody = styled.div`
	height: 100%;
`;
